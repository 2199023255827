import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, TouchableOpacity} from 'react-native';
import { DefaultTheme, Provider as PaperProvider } from 'react-native-paper';
import { HelperText, Button, Card, Title, Paragraph, List, Portal, Dialog, DataTable, RadioButton, Checkbox, Drawer, TextInput } from 'react-native-paper';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { emailValidator, passwordValidator } from '../utils/index';


const theme = {
    ...DefaultTheme,
    roundness: 2,
    myOwnProperty: true,
    colors: {
      ...DefaultTheme.colors,
      background: '#292929',
      primary: 'white',
      accent: 'white',
      text: '#ffffff',
      surface:'#292929'
    },
  };

function ForgotPasswordScreen({ navigation, route }) {

    const [email,setEmail] = useState({ value: '', error: '' })
    const [password, setPassword] = useState({ value: '', error: '' });

    const [firstOpen,setFirstOpen] = useState()

    const getData = async () => {
        try {
          const value = await AsyncStorage.getItem('@id_usertable')
          return value
        }
        catch(e) {
          // error reading value
        }
      }

    const onLoginPressed = () => {
        const emailError = emailValidator(email.value);
        const passwordError = passwordValidator(password.value);
    
        // if (emailError || passwordError) {
        //     var tempEmail = email
        //     email.error =emailError
        //     setEmail({...email, tempEmail})
        //     var tempPass = password
        //     password.error = passwordError
        //     setPassword({...password, passwordError})
        //     return
        // }
    
        navigation.navigate('Home')
    };

    useEffect(()=>{

      }, [firstOpen])

    return (
        <PaperProvider theme={theme}>
            <View style={{flex: 1, flexDirection: 'column', justifyContent: 'center', backgroundColor: theme.colors.background }} >
                <View style={{flex: 3}} />
                <View style={{flex: 1}} />
                <View style={{flex: 4}}>
                    <View style={{flex: 1,flexDirection: 'row'}}>
                        <View style={{flex:1}} />
                        <View style={{flex:6}}>
                            <TextInput
                                label="Email"
                                returnKeyType="next"
                                value={email.value}
                                onChangeText={text => setEmail({ value: text, error: '' })}
                                error={!!email.error}
                                autoCapitalize="none"
                                autoCompleteType="email"
                                textContentType="emailAddress"
                                keyboardType="email-address"
                                underlineColor="grey"
                                theme={{ colors: { placeholder: 'grey' } }}
                            />
                            <HelperText type="error" visible={!!email.error}>
                                {email.error}
                            </HelperText>
                        </View>
                        <View style={{flex:1}} />
                    </View>
                    <View style={{flex: 1,flexDirection: 'row'}}>
                        <View style={{flex:1}} />
                        <View style={{flex:6}}>
                            <TextInput
                                label="Password"
                                returnKeyType="done"
                                value={password.value}
                                onChangeText={text => setPassword({ value: text, error: '' })}
                                error={!!password.error}
                                errorText={password.error}
                                underlineColor="grey"
                                theme={{ colors: { placeholder: 'grey' } }}
                                secureTextEntry
                            />
                            <HelperText type="error" visible={!!password.error}>
                                {password.error}
                            </HelperText>
                        </View>
                        <View style={{flex:1}} />
                    </View>
                </View>
                <View style={{flex: 1}} />
                <View style={{flex: 1}}>
                    <View style={{flex: 1,flexDirection: 'row'}}>
                        <View style={{flex:1}} />
                        <View style={{flex:6}}>
                            <Button mode="contained" 
                                color="#2196f3"
                                onPress={onLoginPressed}
                            >
                                Login
                            </Button>
                        </View>
                        <View style={{flex:1}} />
                    </View>
                </View>
                <View style={{flex: 1}}>
                    <View style={{flex: 1,flexDirection: 'row'}}>
                        <View style={{flex:1}} />
                        <View style={{flex:2}}>
                            <TouchableOpacity onPress={() => navigation.navigate('ForgotPassword')}>
                                <Text style={{textAlign: 'left', color:'white'}}>Do you have an account? Sign in</Text>
                            </TouchableOpacity>
                        </View>
                        <View style={{flex:1}} />
                        <View style={{flex:2}}>
                            
                            <TouchableOpacity onPress={() => navigation.navigate('SignUp')}>
                                <Text style={{textAlign: 'right', color:'white'}}>Don’t have an account? Sign up</Text>
                            </TouchableOpacity>
                        </View>
                        <View style={{flex:1}} />
                    </View>
                </View>
                <View style={{flex: 3}} />
            </View>
        </PaperProvider>
    );
}

export default ForgotPasswordScreen

const styles = StyleSheet.create({
    title: {
      fontSize: 16,
      textTransform: 'uppercase',
    },
    text: {
        fontSize: 14,
        textTransform: 'uppercase',
    },
    boxParameters :{
        padding: 2
    }
  });